<template>
    <div class="conteiner">
        <formgroup label="" :validator="$v.textBusqueda">
            <input class="form-control" type="text" v-model="textBusqueda" />
        </formgroup>
        <h6 class="text-align-center">Etiquetas</h6>

    </div>
</template>

<script>
import { formgroup } from "@justiciacordoba/vue-formgroup";


export default {
    name: "EtiquetasDocumento",
    components: {
        formgroup
    },
    data() {
        return {
            etiquetas: [],
            textBusqueda: ''
        }
    },
    validations: {
        textBusqueda: {}
    }
}
</script>

<style></style>