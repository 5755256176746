import axios from "axios";
import Keycloak from "keycloak-js";

export default async function(vueInstance) {
  const initOptions = {
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    url: process.env.VUE_APP_KEYCLOAK_SERVER_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: "login-required",
    pkceMethod: "S256",
  };

  const keycloak = new Keycloak(initOptions);
  await keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        setInterval(() => {
          keycloak
            .updateToken(70)
            .then((refreshed) => {
              if (refreshed) {
                console.log("Token refreshed" + refreshed);
                vueInstance.$store.commit("setToken", keycloak.token);
              }
            })
            .catch(function() {
              console.log(
                "Failed to refresh the token, or the session has expired"
              );
            });
        }, 10000);
      }
    })
    .catch(() => {
      console.log("Authenticated Failed");
    })
    .finally(() => {
      keycloak.loadUserInfo().then((info) => {
        console.dir(keycloak);

        axios.get("./api/Sistema/Usuario").then((response) => {
          if (response.status == 200) {
            console.dir(response);
            vueInstance.$store.commit("setEsDependenciaAdmin", response.data.permEsDependenciaAdmin);
            vueInstance.$store.commit("setCodigoDependencia", response.data.codigoDependencia);
            vueInstance.$store.commit("setNombreDependencia", response.data.nombreDependencia);
            vueInstance.$store.commit("setCodigoOrganismo", response.data.codigoOrganismo);
            vueInstance.$store.commit("setNombreOrganismo", response.data.nombreOrganismo);
          }
        });

        vueInstance.$store.commit("setLoggedIn", true);
        vueInstance.$store.commit("setLoggedIn", true);
        vueInstance.$store.commit("setUserInfo", info);

        vueInstance.$store.commit("setToken", keycloak.token);
        vueInstance.$store.commit("setLogOut", keycloak.createLogoutUrl());

        vueInstance.$emit("auth-ready");
      });
    });
}
