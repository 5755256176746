<template>
  <div class="d-flex flex-column gap-3">
    <div>
      <h3>Dependencias</h3>

      <hr />

      <div class="text-center py-3" v-show="parametros.loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      </div>

      <div v-show="!parametros.loading">
        <div class="d-flex align-items-center justify-content-end bg-light rounded px-3 py-2 small">
          <div class="btn-toolbar gap-2 " role="toolbar" aria-label="Barra de herramientas">
            <div class="d-flex align-items-center px-1">
              <span class="text-muted">Nombre:</span>
            </div>

            <div class="input-group input-group-sm">
              <input type="text" class="form-control form-control-sm" v-model="modelo.descripcion"
                v-on:keyup.enter="buscar" />
              <button type="button" class="btn btn-sm btn-primary" id="btnBuscar" @click="buscar">
                <i class="bi bi-search"></i>
              </button>
            </div>

            <div class="vr"></div>

            <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Agregar una nueva dependencia" @click="agregar">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!parametros.loading">
      <div class="rounded border overflow-hidden">
        <table class="table table-borderless align-middle m-0">
          <thead class="table-light">
            <tr>
              <th scope="col">Dependencia</th>
              <th scope="col">Contacto</th>
              <!-- <th scope="col">Descripción</th> -->
              <th scope="col">Fuero</th>
              <th scope="col">Ciudad</th>
              <th scope="col" class="text-center"></th>
            </tr>
          </thead>

          <tbody class="border-top-0 small">
            <tr v-for="d in datos.dependencias" :key="d.codigo_dependencia" class="border-top">
              <td scope="row" data-label="Dependencia">
                {{ d.nombre }}
              </td>
              <td scope="row" data-label="Contacto">
                {{ d.datosContacto.nombreCompleto }}
              </td>
              <!-- <td scope="row" data-label="Descripción">
              {{ d.descripcion }}
            </td> -->
              <td scope="row" data-label="Fuero">
                {{ d.fuero }}
              </td>
              <td scope="row" data-label="Ciudad">
                {{ d.ubicacion.ciudad }}
              </td>
              <td class="text-center">
                <button type="button" class="btn btn-sm btn-secondary"
                  @click="editar(d.idDependencia.codigoDependencia)">
                  <i class="bi bi-pencil-square me-1"></i>
                  Editar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <vue-pagination v-model="datos.dependencias" :page-index="parametros.numeroPagina"
      :page-size="parametros.registrosPagina" @page-change="
        parametros.numeroPagina = $event;
      buscar();
      ">
    </vue-pagination>

    <b-modal hide-footer id="DependenciasModal" size="xl" @hide="onModalHide" @hidden="buscar" :title="tituloModal">
      <DependenciasABM :id="parametros.dependencia" @closeModal="closeModal" @unsavedChanges="setUnsavedChanges" />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import DependenciasABM from "./DependenciasABM.vue";
import vuePagination from "@justiciacordoba/vue-pagination";
import modal from "@justiciacordoba/js-modal";

export default {
  name: "Dependencias",
  components: {
    DependenciasABM,
    vuePagination,
  },
  data() {
    return {
      modelo: {
        descripcion: "",
        organismoUsuarioNombre: this.$store.getters.nombreOrganismo,
        organismoUsuarioCodigo: this.$store.getters.codigoOrganismo,
      },
      datos: {
        dependencias: [],
      },
      parametros: {
        dependencia: "",
        numeroPagina: 0,
        registrosPagina: 10,
        filtroModificado: false,
        loading: true,
      },
      hasUnsavedChanges: false,
    };
  },
  validations: {
    modelo: {
      descripcion: {},
    },
  },
  mounted() {
    this.obtener();
  },
  methods: {
    abrirModal() {
      this.$bvModal.show("DependenciasModal");
    },
    // cerrarModal(event) {
    //   if (this.hasUnsavedChanges) {
    //     const confirmClose = window.confirm('Tienes cambios sin guardar. ¿Estás seguro que deseas cerrar el modal?')
    //     if (!confirmClose) {
    //       event.preventDefault();
    //     }
    //   }
    // },
    onModalHide(event) {
      if (this.hasUnsavedChanges) {
        event.preventDefault();
        this.mostrarConfirmacionCerrarModal();
      }
    },
    mostrarConfirmacionCerrarModal() {
      const options = {
        confirmTitle: "Cambios sin guardar",
        confirmMessage:
          "Tienes cambios sin guardar. ¿Estás seguro de que deseas cerrar?",
        confirmOk: "Aceptar",
        confirmCancel: "Cancelar",
        confirmDirection: "ltr",
        confirmStyle: "success",
        confirmCallback: () => {
          this.hasUnsavedChanges = false;
          this.closeModal()
        },
      };

      modal.confirmModal(options);
    },
    setUnsavedChanges(hasChanges) {
      this.hasUnsavedChanges = hasChanges
    },
    closeModal() {
      this.$bvModal.hide("DependenciasModal");
    },
    agregar() {
      this.parametros.dependencia = "";
      this.$bvModal.show("DependenciasModal");
    },
    editar(id) {
      this.parametros.dependencia = id;
      this.$bvModal.show("DependenciasModal");
    },
    buscar() {
      this.$bvModal.hide("DependenciasModal");
      this.obtener();
    },
    obtener() {
      if (this.parametros.filtroModificado) {
        this.parametros.numeroPagina = 0;
        this.parametros.filtroModificado = false;
      }

      // this.parametros.loading = true;

      axios
        .get(
          `../api/Dependencias/Lista?Nombre=${this.modelo.descripcion}&CodigoOrganismo=${this.modelo.organismoUsuarioCodigo}&NumeroPagina=${this.parametros.numeroPagina}&RegistrosPorPagina=${this.parametros.registrosPagina}`
        )
        .then((r) => {
          if (r.data.isSuccess) {
            this.datos.dependencias = r.data.data.lista;
            this.parametros.loading = false;
          }
        });
    },
  },
  // created() {
  //   if (this.$store.getters.nombreOrganismo !== "") {
  //     this.modelo.organismoUsuarioNombre = this.$store.getters.nombreOrganismo;
  //   }
  // },
  computed: {
    tituloModal() {
      return this.parametros.dependencia
        ? "Editar dependencia"
        : "Agregar dependencia";
    },
    descripcion() {
      return this.modelo.descripcion;
    },
  },
  watch: {
    descripcion() {
      this.parametros.filtroModificado = true;
    },
  },
};
</script>

<style></style>
