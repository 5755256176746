<template>
    <div>
        <input ref="fecha"
        type="text"
        :class="datepickerClass"
        autocomplete="off"
        spellcheck="false"
        :placeholder="placeholder"
        maxlength="10"
        v-model="bindingData"
        v-show="!getImpresion">
        <input type="text" ref="sololectura" :class="inputClass" maxlength="10" autocomplete="off" readonly v-model="bindingData" v-if="getImpresion" />
    </div>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'

moment.locale("es")

import 'jqueryui/jquery-ui.css'
import 'jqueryui'
import 'jquery-mask-plugin'

$.datepicker.regional['es'] = {
    closeText: 'Cerrar',
    prevText: '< Ant',
    nextText: 'Sig >',
    currentText: 'Hoy',
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
    weekHeader: 'Sm',
    dateFormat: 'dd/mm/yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
};

$.datepicker.setDefaults($.datepicker.regional['es']);
$.datepicker._gotoToday = function (id) { $(id).datepicker('setDate', new Date()).datepicker('hide').blur(); };

export default {
    name: "datepicker",
    props: {
        value: { default: null },
        datepickerClass: { default: "form-control" },
        inputClass: { default: "form-control-plaintext text-monospace" },
        placeholder: { default: "DD/MM/AAAA"}
    },
    data () {
        return {
            bindingData: ""
        }
    },
    computed: {
        getImpresion: function () {
            if (typeof this.$store === "undefined") {
                return false;
            } else {
                return this.$store.state.Impresion;
            }
        }
    },
    methods: {
        trigger: function (date) {
            if (date == null) {
                this.$emit("input", null);
                this.bindingData = null;
            } else if (date.length == 10) {
                var validar = moment(date, "DD/MM/YYYY").isValid();

                if (validar) {
                    this.$emit("input", moment(date, "DD/MM/YYYY").format());
                } else {
                    this.$emit("input", null);
                    this.bindingData = null;
                }
            }
        }
    },
    mounted () {
        const dp = this;
        $(this.$refs.fecha)
            .mask("00/00/0000", {
                onChange: function (date) {
                    dp.bindingData = date;
                    dp.trigger(date);
                }
            })
            .datepicker({
                showButtonPanel: false,
                changeMonth: true,
                changeYear: true,
                gotoCurrent: true
            })
            .on("change", function () {
                if (this.value.length == 10)
                    dp.trigger(this.value);
            })
            .focusout(function () {
                if (this.value.length < 10)
                    dp.trigger(null);
            })
    },
    watch: {
        value: function (newVal) {
            if (newVal == null) {
                this.bindingData = null;
            } else {
                this.bindingData = moment(newVal).format("DD/MM/YYYY");
            }
        }
    }
}
</script>

<style scoped>
</style>
