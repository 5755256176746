<template>
  <div>
    <div>
      <h3>Documentos</h3>
    </div>
    <div>
      <hr />
    </div>

    <div class="d-flex flex-column flex-lg-row flex-fill gap-3">
      <!-- <componente-filtros @update-filters="handleFiltros" /> -->
      <div class="bg-light rounded flex-shrink-0 p-3" id="sidebar">
        <div class="d-flex justify-content-between align-items-center">
          <button
            class="btn btn-sidebar-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 p-0"
            @click="toggleSidebar">
            <i class="bi bi-list fs-4"></i>
            <span class="fw-semibold fs-4 ms-2">Filtros</span>
          </button>
        </div>


        <div class="filter-list">
          <hr class="mt-3 mb-2" />

          <div class="mb-2">
            <span role="button" class="selector-buzon hover d-block rounded px-3 py-2" :class="{
              'bg-secondary text-light': parametros.buzon == 'Recibidos',
            }" @click="cambiarBuzon">
              Recibidos
            </span>

            <span role="button" class="selector-buzon hover d-block rounded px-3 py-2" :class="{
              'bg-secondary text-light': parametros.buzon == 'Enviados',
            }" @click="cambiarBuzon">
              Enviados
            </span>
          </div>

          <ul class="list-unstyled mx-2 mb-0">
            <li>
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse" data-bs-target="#fecha-collapse" aria-expanded="true">
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Fecha</span>
              </button>
              <div class="collapse show" id="fecha-collapse">
                <div class="btn-toggle-nav fw-normal mx-3 px-1 py-1 small">
                  <div v-show="!modelo.filtrar.fecha">
                    <span role="button" class="hover d-block link-dark rounded p-1"
                      v-for="(f, i) in datos.filtros.fecha" :key="i" @click="agregarFiltroFecha(f)">
                      {{ f.descripcion }}
                    </span>
                  </div>
                  <div v-show="modelo.filtrar.fecha">
                    <div class="d-inline-flex align-items-center p-1">
                      <span class="lh-sm fw-semibold me-1">
                        {{ modelo.fecha.descripcion }}
                      </span>
                      <i role="button" class="bi bi-x btn-quitar-filtro" title="Quitar filtro"
                        @click="quitarFiltroFecha"></i>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse" data-bs-target="#rango-collapse" aria-expanded="false">
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Rango de Fechas</span>
              </button>
              <div class="collapse" id="rango-collapse">
                <div class="row gap-2 mx-2 px-2 py-1 small">
                  <div class="col-sm-5" style="width: 6em; padding: 0;">
                    <DatePicker placeholder="Desde" v-model="modelo.fechaDesde"/>
                  </div>
                  <div class="col-sm-5" style="width: 6em; padding: 0;">
                    <DatePicker placeholder="Hasta" v-model="modelo.fechaHasta"/>
                  </div>
                  <div class="col-sm-1" style="padding-left: 0; display: flex">
                    <button type="button" class="btn btn-sm bg-secondary text-light" title="Buscar" @click="obtener()">
                      <i class="bi bi-chevron-double-right"></i>
                    </button>
                    <span v-if="modelo.fechaDesde || modelo.fechaHasta"
                      style="cursor: pointer; display: flex; align-items: center; margin-left: 5px;"
                      @click="resetFiltro('rango')">
                      &times;
                    </span>
                  </div>
                </div>
              </div>
            </li>

            <li>
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse" data-bs-target="#causa-collapse" aria-expanded="false">
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Causa</span>
              </button>
              <div class="collapse" id="causa-collapse">
                <div class="btn-toggle-nav fw-normal mx-3 px-1 py-1 small">
                  <div class="d-flex align-items-center">
                    <div class="form-check me-3">
                      <input class="form-check-input" type="radio" name="opcion" id="checkOrigen" value="origen"
                        v-model="modelo.origenDestinoCausa" @change="onFilterChangeCausa">
                      <label class="form-check-label small" for="checkOrigen">
                        Origen
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="opcion" id="checkDestino" value="destino"
                        v-model="modelo.origenDestinoCausa" @change="onFilterChangeCausa" />
                      <label class="form-check-label small" for="checkDestino">
                        Destino
                      </label>
                    </div>
                  </div>
                  <div class="row" style="display: flex; ">
                    <div class="col-sm-9 form-group my" style="display: flex; align-items: center; width: 12em; ">
                      <formgroup-input placeHolder="Número de Causa" style="flex: 1;" v-model="modelo.numeroCausa" label="" :validator="$v"
                        @input="onFilterChangeCausa"></formgroup-input>
                    </div>
                    <div class="col-sm-1" style="padding-left: 0; display: flex;  align-items: start;">
                      <button type="button" class="btn btn-sm bg-secondary text-light" title="Buscar"
                        @click="obtener()">
                        <i class="bi bi-chevron-double-right"></i>
                      </button>
                    </div>
                    <div class="col-sm-1" style="display: flex; align-items: center; margin-left: 10px;">
                      <span v-if="modelo.numeroCausa" style="cursor: pointer;" @click="resetFiltro('causa')">
                        &times;
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </li>

            <li v-if="
              !this.$store.getters.nombreDependencia ||
              parametros.buzon == 'Recibidos'
            ">
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse" data-bs-target="#origen-collapse" aria-expanded="false">
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Origen</span>
              </button>

              <div class="collapse" id="origen-collapse">
                <div class="btn-toggle-nav fw-normal mx-3 px-2 py-1 small">
                  <div v-if="parametros.buzon == 'Recibidos'">
                    <span>Organismo</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.origen.organismo">
                        <formgroup :validator="$v.modelo.origen.organismo" v-slot="scope">
                          <vue-searcher id="buscadorOrganismo" data-field-id="codigoOrganismo" data-field-text="nombre"
                            page-size="10" url-search="../../api/Organismos/Lista?Provincia=[Criterio]"
                            url-get="../../api/Organismos/" url-search-key="[Criterio]" list-property-name="lista"
                            object-property-name="organismo" placeholder="Buscar organismo.." :allow-pagging="true"
                            v-model="modelo.origen.organismo" style="width: fit-content"
                            :ajax-settings="setAjaxSettings" @on-item-selected="forceRerender(scope)"
                            @on-cleaning="limpiarDependenciaOrigen(scope)"
                            @keyup.enter="agregarFiltro('destino', 'organismo')"></vue-searcher>
                        </formgroup>
                      </div>
                      <div v-show="modelo.filtrar.origen.organismo">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.origen.organismo
                          }}</span>
                          <i class="bi bi-x btn-quitar-filtro" title="Quitar filtro"
                            @click="quitarFiltro('origen', 'organismo')"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span>Dependencia</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.origen.dependencia">
                        <vue-searcher ref="searcherDependenciaOrigen" id="buscadorDependenciaOrigen"
                          data-field-id="codigoDependencia" data-field-text="nombre" page-size="10"
                          :url-search="getDependenciaOrigen"
                          url-get="../../api/Dependencias/ListaSearcher?CodigoOrganismo=" url-search-key="[Criterio]"
                          list-property-name="lista" object-property-name="codigoOrganismo"
                          placeholder="Buscar dependencia.." :allow-pagging="true" v-model="modelo.origen.dependencia"
                          style="width: fit-content" :key="datos.keyDependenciaOrigen" :ajax-settings="setAjaxSettings"
                          @keyup.enter="agregarFiltro('origen', 'dependencia')" @on-item-selected="obtener()"
                          @on-cleaning="obtener()"></vue-searcher>
                      </div>
                      <div v-show="modelo.filtrar.origen.dependencia">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.origen.dependencia
                          }}</span>
                          <i class="bi bi-x btn-quitar-filtro" title="Quitar filtro"
                            @click="quitarFiltro('origen', 'dependencia')"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li v-if="
              !this.$store.getters.nombreDependencia ||
              parametros.buzon == 'Enviados'
            ">
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse" data-bs-target="#destino-collapse" aria-expanded="false">
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Destino</span>
              </button>

              <div class="collapse" id="destino-collapse">
                <div class="btn-toggle-nav fw-normal mx-3 px-2 py-1 small">
                  <div v-if="parametros.buzon == 'Enviados'">
                    <span>Organismo</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.destino.organismo">
                        <formgroup :validator="$v.modelo.destino.organismo" v-slot="scope">
                          <vue-searcher id="buscadorOrganismo" data-field-id="codigoOrganismo" data-field-text="nombre"
                            page-size="10" url-search="../../api/Organismos/Lista?Provincia=[Criterio]"
                            url-get="../../api/Organismos/" url-search-key="[Criterio]" list-property-name="lista"
                            object-property-name="organismo" placeholder="Buscar organismo.." :allow-pagging="true"
                            v-model="modelo.destino.organismo" style="width: fit-content"
                            :ajax-settings="setAjaxSettings" @keyup.enter="agregarFiltro('destino', 'organismo')"
                            @on-item-selected="forceRerender(scope)"
                            @on-cleaning="limpiarDependenciaDestino(scope)"></vue-searcher>
                        </formgroup>
                      </div>
                      <div v-show="modelo.filtrar.destino.organismo">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.destino.organismo
                          }}</span>
                          <i class="bi bi-x btn-quitar-filtro" title="Quitar filtro"
                            @click="quitarFiltro('destino', 'organismo')"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span>Dependencia</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.destino.dependencia">
                        <vue-searcher ref="searcherDependenciaDestino" id="buscadorDependenciaDestino"
                          data-field-id="codigoDependencia" data-field-text="nombre" page-size="10"
                          :url-search="getDependenciaDestino"
                          url-get="../../api/Dependencias/ListaSearcher?CodigoOrganismo=" url-search-key="[Criterio]"
                          list-property-name="lista" object-property-name="codigoOrganismo"
                          placeholder="Buscar dependencia.." :allow-pagging="true" v-model="modelo.destino.dependencia"
                          style="width: fit-content" :ajax-settings="setAjaxSettings" :key="datos.keyDependenciaDestino"
                          @keyup.enter="agregarFiltro('origen', 'dependencia')" @on-item-selected="obtener()"
                          @on-cleaning="obtener()"></vue-searcher>
                      </div>
                      <div v-show="modelo.filtrar.destino.dependencia">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.destino.dependencia
                          }}</span>
                          <i class="bi bi-x btn-quitar-filtro" title="Quitar filtro"
                            @click="quitarFiltro('destino', 'dependencia')"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex-grow-1">
        <div class="rounded p-3 row">
          <span class="fs-4 fw-semibold col-10">Documentos {{ parametros.buzon }}</span>
          <div class="col-1 justify-content-end centrado pe-0" aria-label="Barra de herramientas">
            <div class="search-container">
              <input class="search expandright" id="searchright" type="search" name="q" placeholder="Buscar por ID"
                v-model="idDocBusqueda" @keypress.enter="handleSearch(idDocBusqueda)">
              <label class="button centrado searchbutton" for="searchright" @click="handleSearch(searchId)"><i
                  class="bi bi-search"></i></label>
            </div>
          </div>
          <div class="col-1 centrado ps-0" aria-label="Barra de herramientas">
            <button type="button" class="btn btn-sm btn-primary" title="Recargar" @click="obtener()">
              <i class="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>

        <hr class="mt-0 mb-2" />

        <div class="text-center py-3" v-show="parametros.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
        </div>

        <div v-show="!parametros.loading">
          <div v-for="doc in datos.documentosEnProceso" :key="doc.id">
            <div role="button" class="d-flex flex-column rounded hover px-3 py-2 gap-2">
              <div class="d-flex align-items-center justify-content-between fw-semibold">
                <span class="text-muted">{{ doc.id }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-between small">
                <span class="badge bg-primary" v-if="doc.estadoOperacion == 'EN_PROCESO'">
                  Pendiente
                </span>
              </div>
            </div>
          </div>
          <div v-for="d in datos.documentos" :key="d.id">
            <div role="button" class="d-flex flex-column rounded hover px-3 py-2 gap-2"
              :class="{ 'selected': d === documentoSeleccionado }" @click="verDocumento(d)">
              <div class="d-flex align-items-center justify-content-between fw-semibold">
                <div class="d-inline-block w-50 d-flex align-items-center">
                  <!-- En Recibidos solo mostrar Nuevo (cuando es entregado) o descargado -->
                  <span class="badge bg-primary me-1"
                    v-if="parametros.buzon === 'Recibidos' && d.estadoConfirmacion === 'ENVIADO_OK' && d.estadoOperacion !== 'RECHAZADO'">
                    Nuevo
                  </span>
                  <span class="badge bg-secondary me-1" v-if="d.tipoEnvio == 'CON_ADJUNTOS'">
                    <i class="bi bi-paperclip"></i>
                  </span>
                  <span
                    v-if="parametros.buzon == 'Recibidos' && d.estadoConfirmacion === 'DESTINO_RECIBIDO' && d.estadoOperacion !== 'RECHAZADO'"
                    class="badge me-1" :style="{ backgroundColor: getEstadoColor(d.estadoConfirmacion) }">
                    {{ getEstadoDescripcion(d.estadoConfirmacion) }}
                  </span>
                  <span
                    v-if="parametros.buzon == 'Recibidos' && d.estadoConfirmacion === 'DESTINO_PROCESADO' && d.estadoOperacion !== 'RECHAZADO'"
                    class="badge me-1" :style="{ backgroundColor: getEstadoColor(d.estadoConfirmacion) }">
                    {{ getEstadoDescripcion(d.estadoConfirmacion) }}
                  </span>

                  <!-- En Enviados mostrar todos los estados -->
                  <span
                    v-if="parametros.buzon == 'Enviados' && d.estadoConfirmacion && d.estadoOperacion !== 'RECHAZADO'"
                    class="badge me-1" :style="{ backgroundColor: getEstadoColor(d.estadoConfirmacion) }">
                    {{ getEstadoDescripcion(d.estadoConfirmacion) }}
                  </span>

                  <span class="badge bg-danger" v-if="
                    parametros.buzon == 'Enviados' &&
                    d.estadoOperacion == 'RECHAZADO'
                  ">
                    {{ capitalize(d.estadoOperacion) }}
                  </span>
                </div>

                <span class="d-inline-block w-50 text-muted text-end small me-3">
                  {{
                    d.timeStamp == null ? "Sin fecha" : "Enviado el " + formatear(d.timeStamp)
                  }}</span>
              </div>

              <div><span class="d-inline-block w-100 fw-bold">Causa</span></div>
              <div class="d-flex align-items-start justify-content-between small">
                <span class="d-inline-block" style="width: 20%;">{{
                  d.causaOrigen.numero
                }} </span>
                <span class="d-inline-block" style="width: 80%;">{{
                  d.causaOrigen.caratula
                }}
                </span>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <span class="d-inline-block w-50"
                  :class="parametros.buzon === 'Recibidos' ? 'fw-bold' : 'fw-semibold'">Origen</span>
                <span class="d-inline-block w-50 text-star"
                  :class="parametros.buzon === 'Enviados' ? 'fw-bold' : 'fw-semibold'">Destino</span>
              </div>
              <div class="d-flex align-items-center justify-content-between small">
                <span class="d-inline-block text-truncate w-50"
                  :class="parametros.buzon === 'Recibidos' && 'fw-bold enfasis'">{{
                    d.causaOrigen.dependencia.nombreOrganismo
                  }}</span>
                <span class="d-inline-block text-truncate w-50 text-start"
                  :class="parametros.buzon === 'Enviados' && 'fw-bold enfasis'">{{
                    d.causaDestino.dependencia.nombreOrganismo
                  }}</span>
              </div>
              <div class="d-flex align-items-start justify-content-between small">
                <span class="d-inline-block w-50" :class="parametros.buzon === 'Recibidos' && 'fw-bold enfasis'">{{
                  d.causaOrigen.dependencia.nombreDependencia
                }}</span>
                <span class="d-inline-block text-truncate w-50 text-start"
                  :class="parametros.buzon === 'Enviados' && 'fw-bold enfasis'">{{
                    d.causaDestino.dependencia.nombreDependencia
                  }}</span>
              </div>

            </div>
            <div class="d-flex align-items-center justify-content-between small"
              v-if="d.idRespuesta != '' && d.idRespuesta != null">
              <span class="d-inline-block text-truncate w-50 text-left fw-bold" style="margin-left: 15px">
                En respuesta a:
                <a href="#" class="link-primary mx-1" @click="verDocumentoRespuesta(d)">
                  Ver Mensaje
                </a>
              </span>
            </div>

            <hr class="my-2" v-if="d.id != null" />
          </div>
          <vue-pagination v-model="datos.documentos" :page-index="parametros.numeroPagina"
            :page-size="parametros.registrosPagina" @page-change="
              parametros.numeroPagina = $event;
            obtener();
            ">
          </vue-pagination>

          <div v-show="!parametros.loading" v-if="!datos.documentos.length">
            <div class="rounded px-3 py-2">
              <div class="d-flex align-items-center justify-content-center my-3">
                <span class="fw-semibold fs-5">No hay documentos disponibles.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal hide-footer id="VisualizarDocumentoModal" size="lg">
      <!-- :title="datos.titulo" -->
      <template #modal-title>
        {{ datos.titulo }}
        <button class="btn btn-link btn-sm p-0 ms-2" data-bs-toggle="tooltip" ref="copiarTooltip"
          @click="copiarAlPortapapeles(datos.titulo)">
          <i class="bi bi-clipboard"></i>
        </button>
      </template>
      <VisualizarDocumento :ticket="datos.documento" :buzon="parametros.buzon" @closeModal="closeModalAction" />
    </b-modal>

    <b-modal id="ErrorModal" title="Error" ok-only @ok="idDocBusqueda = ''">
      <!-- Mostrar el mensaje de error que pasamos desde la función -->
      <p>{{ errorMessage }}</p>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import vueSearcher from "@justiciacordoba/vue-searcher";
// import vueDatepicker from "@justiciacordoba/vue-datepicker"
import DatePicker from "../../components/DatePicker.vue";
// import componenteFiltros from "./FiltrosDocumentos.vue"
import modal from "@justiciacordoba/js-modal";
import { Tooltip } from 'bootstrap';
import { numeric } from "vuelidate/lib/validators";
import VisualizarDocumento from "./VisualizarDocumento.vue";
import moment from "moment";
import "moment/locale/es";
import vuePagination from "@justiciacordoba/vue-pagination";
import { formgroupInput, formgroup } from "@justiciacordoba/vue-formgroup";
moment.locale("es");

export default {
  name: "Documentos",
  components: {
    VisualizarDocumento,
    vueSearcher,
    // componenteFiltros,
    vuePagination,
    formgroup,
    formgroupInput,
    // vueDatepicker,
    DatePicker
  },
  computed: {
    buzon() {
      return this.parametros.buzon;
    },
    getDependenciaDestino() {
      var url = "";
      if (this.parametros.buzon == "Recibidos") {
        url =
          "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
          this.$store.getters.codigoOrganismo +
          "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";
      } else {
        url =
          "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
          this.modelo.destino.organismo +
          "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";
      }
      return url;
    },
    getDependenciaOrigen() {
      var url =
        "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
        this.modelo.origen.organismo +
        "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";

      return url;
    },
    setAjaxSettings() {
      return {
        headers: { Authorization: "Bearer " + this.$store.getters.token },
      };
    },
    //   filtrosCausa() {
    //   if (this.modelo.origenDestinoCausa === 'origen') {
    //     return {
    //       origenCausaNumero: this.numeroCausa,
    //       destinoCausaNumero: null
    //     };
    //   } else if (this.modelo.origenDestinoCausa === 'destino') {
    //     return {
    //       origenCausaNumero: null,
    //       destinoCausaNumero: this.numeroCausa
    //     };
    //   }
    //   return {
    //     origenCausaNumero: null,
    //     destinoCausaNumero: null
    //   };
    // }
    // filtrosCausa() {
    //   return {
    //     origenCausaNumero: this.modelo.origenDestinoCausa === 'origen' ? this.numeroCausa : null,
    //     destinoCausaNumero: this.modelo.origenDestinoCausa === 'destino' ? this.numeroCausa : null,
    //   };
    // }
  },
  data() {
    return {
      modelo: {
        fecha: {
          descripcion: null,
          valor: null,
        },
        origen: {
          organismo: null,
          dependencia: null,
        },
        destino: {
          organismo: null,
          dependencia: null,
        },
        numeroCausa: '',
        origenDestinoCausa: 'origen',
        origenCausaNumero: '',
        destinoCausaNumero: '',
        isOrganismoUsuario: false,
        fechaDesde: '',
        fechaHasta: '',
        origenDestinoDep: 'origen',
        filtrar: {
          fecha: false,
          origen: {
            organismo: null,
            dependencia: null,
          },
          destino: {
            organismo: null,
            dependencia: null,
          },

        },
      },
      errorMessage: '',
      // filtros: {},
      datos: {
        filtros: {
          fecha: [
            {
              descripcion: "Hoy",
              valor: "Hoy",
            },
            {
              descripcion: "Ayer",
              valor: "Ayer",
            },
            {
              descripcion: "Última semana",
              valor: "Ultimasemana",
            },
            {
              descripcion: "Último mes",
              valor: "Ultimomes",
            },
          ],
        },
        documentos: [],
        documentosEnProceso: [],
        documento: {},
        titulo: "",
        keyDependenciaOrigen: 0,
        keyDependenciaDestino: 0,
      },
      parametros: {
        buzon: "Recibidos",
        loading: true,
        controller: null,
        numeroPagina: 0,
        registrosPagina: 10,
      },
      documentoSeleccionado: null,
      idDocBusqueda: null,
      estadoConfirmacionDescripciones: {
        ENVIADO_ENPROCESO: 'En proceso de envío',
        ENVIADO_OK: 'Entregado',
        DESTINO_RECIBIDO: 'Descargado',
        DESTINO_PROCESADO: 'Procesado',
      },
      estadoConfirmacionColores: {
        ENVIADO_ENPROCESO: '#d4a404',
        ENVIADO_OK: '#397120',
        DESTINO_RECIBIDO: '#28a745',
        DESTINO_PROCESADO: '#5FBD00',
      },
      tooltipTexto: 'Copiado!'
    };
  },
  validations: {
    modelo: {
      origen: {
        organismo: {},
        dependencia: {},
      },
      destino: {
        organismo: {},
        dependencia: {},
      },
    },
    fechaDesde: {},
    fechaHasta: {},
    organismo: {},
    dependencia: {},
    numeroorigen: {
      numeric: numeric,
    },
    numerodestino: {
      numeric: numeric,
    },
  },
  methods: {
    enviarNuevoDocumento() {
      this.$router.push({ path: "/EnviarDocumento/" });
    },
    cambiarBuzon() {
      if (this.parametros.buzon == "Recibidos") {
        this.parametros.buzon = "Enviados";
        this.$router.push({ path: "/Documentos/Enviados" });
      } else {
        this.parametros.buzon = "Recibidos";
        this.$router.push({ path: "/Documentos/Recibidos" });
      }
    },
    onFilterChangeCausa() {
      if (this.modelo.origenDestinoCausa === 'origen') {
        this.modelo.origenCausaNumero = this.modelo.numeroCausa;
        this.modelo.destinoCausaNumero = null;
      } else if (this.modelo.origenDestinoCausa === 'destino') {
        this.modelo.destinoCausaNumero = this.modelo.numeroCausa;
        this.modelo.origenCausaNumero = null;
      }
    },
    onDepOrgChange() {
      if (
        (this.parametros.buzon === 'Recibidos' && this.modelo.origenDestinoDep === 'destino') ||
        (this.parametros.buzon === 'Enviados' && this.modelo.origenDestinoDep === 'origen')
      ) {
        this.modelo.origen.organismo = this.$store.getters.user_info.codigo_organismo;
        this.modelo.isOrganismoUsuario = true;
      } else {
        this.modelo.isOrganismoUsuario = false;
      }
    },
    capitalize(texto) {
      if (!texto) return '';
      return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
    },
    verDocumento(d) {
      this.documentoSeleccionado = d;
      this.datos.documento = d;
      this.datos.titulo = d.id;
      this.$bvModal.show("VisualizarDocumentoModal");
    },
    toggleSidebar() {
      document.getElementById("sidebar").classList.toggle("active");
    },
    getEstadoDescripcion(estado) {
      return this.estadoConfirmacionDescripciones[estado] || '';
    },
    getEstadoColor(estado) {
      return this.estadoConfirmacionColores[estado] || '#6c757d';
    },
    agregarFiltro(tipo, propiedad) {
      this.modelo.filtrar[tipo][propiedad] = true;
    },
    quitarFiltro(tipo, propiedad) {
      this.modelo[tipo][propiedad] = null;
      this.modelo.filtrar[tipo][propiedad] = false;
    },
    agregarFiltroFecha(fecha) {
      this.modelo.fecha.descripcion = fecha.descripcion;
      this.modelo.fecha.valor = fecha.valor;
      this.modelo.filtrar.fecha = true;
      this.obtener();
    },
    quitarFiltroFecha() {
      this.modelo.fecha.descripcion = null;
      this.modelo.fecha.valor = null;
      this.modelo.filtrar.fecha = false;
      this.obtener();
    },
    resetFiltro(name) {
      if (name === 'rango') {
        this.modelo.fechaDesde = null;
        this.modelo.fechaHasta = null;
        this.obtener();
      } else if (name === 'causa') {
        this.modelo.numeroCausa = '';
        this.modelo.origenCausaNumero = '';
        this.modelo.destinoCausaNumero = '';
        this.modelo.origenDestinoCausa = 'origen'
        this.obtener();
      }
    },
    formatear(fecha) {
      let f = moment(fecha).calendar();
      return f;
    },
    copiarAlPortapapeles(texto) {
      navigator.clipboard.writeText(texto)
        .then(() => {
          const tooltipElement = this.$refs.copiarTooltip;

          if (!tooltipElement._tooltipInstance) {
            tooltipElement._tooltipInstance = new Tooltip(tooltipElement, {
              title: 'Copiado',
              trigger: 'manual',
            });
          }

          const tooltip = tooltipElement._tooltipInstance;
          tooltip.setContent({ '.tooltip-inner': 'Copiado' });
          tooltip.show();

          setTimeout(() => {
            tooltip.hide();
          }, 2000);
        })
        .catch((err) => {
          console.error('Error al copiar el texto:', err);
        });
    },
    obtener() {
      if (this.parametros.numeroPagina < 0) {
        return;
      }

      var fechaDesdeApi = this.modelo.fechaDesde || '';
      var fechaHastaApi = this.modelo.fechaHasta || '';

      // if (this.modelo.fechaDesde === null || this.modelo.fechaHasta === null){
      //   fechaDesdeApi = this.modelo.fechaDesde || '';
      //   fechaHastaApi = this.modelo.fechaHasta || '';
      // }

      //Se cambian urlSearchs de dependencia origen y dependencia destino dependiendo si el buzon está en Recibidos o Enviados
      if (this.$refs.searcherDependenciaOrigen != undefined) {
        if (this.$refs.searcherDependenciaOrigen._data != undefined) {
          if (this.parametros.buzon == "Recibidos") {
            this.$refs.searcherDependenciaOrigen._data.searcher.changeSettings(
              "urlSearch",
              "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
              this.modelo.origen.organismo +
              "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]"
            );
          } else {
            this.$refs.searcherDependenciaOrigen._data.searcher.changeSettings(
              "urlSearch",
              "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
              this.$store.getters.user_info.codigo_organismo +
              "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]"
            );
            if (this.$refs.searcherDependenciaDestino != undefined) {
              this.$refs.searcherDependenciaDestino._data.searcher.changeSettings(
                "urlSearch",
                "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
                this.modelo.destino.organismo +
                "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]"
              );
            }
          }
        }
      }

      this.parametros.loading = false;

      // this.datos.documentos = [];

      // VALIDACIONES.
      if (this.modelo.fecha.descripcion != null) {
        this.datos.filtros.fecha.forEach((fec) => {
          if (fec.descripcion == this.modelo.fecha.descripcion) {
            this.modelo.fecha.desde = fec.desde;
            this.modelo.fecha.hasta = fec.hasta;
          }
        });
      } else {
        this.modelo.fecha.desde = moment();
        this.modelo.fecha.hasta = moment();
      }

      if (fechaDesdeApi && fechaHastaApi) {
        if (new Date(fechaHastaApi) < new Date(fechaDesdeApi)) {
          window.alert("La fecha hasta debe ser mayor o igual a la fecha desde.");
          return;
        }
      }

      if (this.parametros.controller) this.parametros.controller.abort();

      this.parametros.controller = new AbortController();

      this.parametros.loading = true;

      this.datos.documentos = [];

      axios
        .get(
          `../../api/Tickets/${this.parametros.buzon}?FechaDesde=${fechaDesdeApi}&FechaHasta=${fechaHastaApi}&FechaNombre=${this.modelo.fecha.valor}&OrigenCodigoOrganismo=${this.modelo.origen.organismo}&OrigenCodigoDependencia=${this.modelo.origen.dependencia}&OrigenCausaNumero=${this.modelo.origenCausaNumero || ''}&DestinoCodigoOrganismo=${this.modelo.destino.organismo}&DestinoCodigoDependencia=${this.modelo.destino.dependencia}&DestinoCausaNumero=${this.modelo.destinoCausaNumero || ''}&NumeroPagina=${this.parametros.numeroPagina}&RegistrosPorPagina=${this.parametros.registrosPagina}`,
          {
            signal: this.parametros.controller.signal,
          }
        )
        .then((r) => {
          if (r.data.isSuccess) {
            this.datos.documentos = r.data.data.lista.filter(
              (doc) => doc.causaOrigen != null
            );
            if (this.parametros.buzon == "Recibidos") {
              this.datos.documentos.filter(
                (doc) => doc.estadoOperacion != "EN_PROCESO"
              );
            }
            this.datos.documentosEnProceso = r.data.data.lista.filter(
              (doc) => doc.estadoOperacion == "EN_PROCESO"
            );
            this.parametros.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.code !== "ERR_CANCELED") this.parametros.loading = false;
        })
        .finally(() => {
          this.parametros.loading = false;
        });
    },
    descargar(id) {
      axios.get(`./api/Documentos/${id}`);
    },
    limpiarDependenciaOrigen(scope) {
      var app = this;

      if (app.modelo.origen.organismo == "") {
        app.modelo.origen.dependencia = "";
      }

      this.forceRerender(scope);

      app.obtener();

      return true;
    },
    forceRerender(scope) {
      scope.change();

      if (this.parametros.buzon == "Recibidos") {
        this.datos.keyDependenciaOrigen += 1;
      } else {
        this.datos.keyDependenciaDestino += 1;
      }

      this.obtener();
    },
    verDocumentoRespuesta(doc) {
      axios.get(`/api/Tickets/${doc.idRespuesta}`).then((response) => {
        this.datos.documento = response.data.data.ticket;
        //this.datos.titulo = response.data.data.ticket.documento.nombre;
        this.$bvModal.show("VisualizarDocumentoModal");
      });
    },
    handleSearch(id) {
      if (id != null) {
        debugger
        axios.get(`/api/Tickets/${id}`).then((response) => {
          if (response.data.isSuccess === true) {
            this.datos.documento = response.data.data.ticket;
            this.datos.titulo = this.datos.documento.id;
            this.$bvModal.show("VisualizarDocumentoModal");
          } else {
            this.errorMessage = response.data.errors[0].errorMessage;

            // Mostrar el modal de error
            this.$bvModal.show("ErrorModal");
          }
        })
      }
    },
    closeModalAction() {
      this.idDocBusqueda = null;
      this.$bvModal.hide('VisualizarDocumentoModal');

    },
    mostrarTicketNoEncontrado(mensaje) {
      const options = {
        confirmTitle: "Documento no encontrado",
        confirmMessage: mensaje,
        confirmOk: "Aceptar",
        confirmDirection: "ltr",
        confirmStyle: "success",
        // confirmCallback: () => {
        //   this.closeModal()
        // },
      };

      modal.confirmModal(options);
    },
    moment,
  },
  watch: {
    buzon() {
      if (this.$refs.searcherDependenciaDestino != undefined) {
        this.$refs.searcherDependenciaDestino._data.searcher
          .getPrivateMethods()
          .clearValue();
        this.$refs.searcherDependenciaDestino._data.searcher
          .getPrivateMethods()
          .clearResults();
      }
      if (this.$refs.searcherDependenciaOrigen != undefined) {
        this.$refs.searcherDependenciaOrigen._data.searcher
          .getPrivateMethods()
          .clearValue();
        this.$refs.searcherDependenciaOrigen._data.searcher
          .getPrivateMethods()
          .clearResults();
      }

      if (this.parametros.buzon === "Recibidos") {
        if (this.modelo.origenDestinoDep === "destino") {
          this.modelo.destino.organismo = this.$store.getters.user_info.codigo_organismo;
          this.$refs.searcherOrganismo._data.searcher.getPrivateMethods().setValue(this.$store.getters.user_info.codigo_organismo);
        }
      }

      this.parametros.numeroPagina = 0;

      this.obtener();
    },
    // modelo: {
    //   handler() {
    //     this.datos.documentos = [];
    //   },
    //   deep: true,
    // },
    "modelo.fecha.valor": {
      handler() {
        if (this.parametros.numeroPagina > 0) this.parametros.numeroPagina = 0;
      },
      deep: true,
    },
    "modelo.origen": {
      handler() {
        if (this.parametros.numeroPagina > 0) this.parametros.numeroPagina = 0;
      },
      deep: true,
    },
    "modelo.destino": {
      handler() {
        if (this.parametros.numeroPagina > 0) this.parametros.numeroPagina = 0;
      },
    },
    deep: true,
  },
  mounted() {
    if (this.$route.params.buzon) {
      this.parametros.buzon = this.$route.params.buzon;
    }
    this.obtener();
  },
};
</script>

<style>
#sidebar {
  min-width: 313px !important;
  transition: min-width ease 0.25s;
}

#sidebar.active {
  min-width: 56px !important;
}

#sidebar .btn-sidebar-toggle i {
  position: relative;
  top: 0.05em;
  color: rgba(0, 0, 0, 0.85) !important;
  transition: transform 0.25s ease !important;
  transform-origin: 0.5em 50% !important;
}

#sidebar.active .btn-sidebar-toggle i {
  color: rgba(0, 0, 0, 0.65) !important;
  transform: scaleX(-1) !important;
}

#sidebar.active .btn-sidebar-toggle span,
#sidebar.active .filter-list {
  display: none;
}

.selector-buzon.active {
  background: rgba(0, 0, 0, 0.5) !important;
}

.btn-toggle {
  color: rgba(0, 0, 0, 0.65) !important;
}

.btn-toggle i {
  padding-top: 0.1em;
  transition: transform 0.25s ease !important;
  transform-origin: 0.5em 50% !important;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, 0.85) !important;
}

.btn-toggle[aria-expanded="true"] i {
  transform: rotate(90deg) !important;
}

.filter-list,
.btn-toggle-nav {
  animation: growdown 0.25s ease forwards;
  transform-origin: top center;
}

@keyframes growdown {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

.btn-quitar-filtro::before {
  position: relative;
  top: 0.05em;
}

.btn-toggle-nav .label-filtro {
  min-height: calc(1.5em + 0.5rem + 2px);
}

#buscadorDependenciaOrigen,
#buscadorOrganismo,
#buscadorDependenciaDestino {
  max-width: 12rem;
}

.JSearcher_ResultContainer {
  position: relative !important;
}

.selected {
  background-color: rgba(0, 0, 0, 0.075);
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);  */
}

.enfasis {
  color: #07416c;
}

.centrado {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: #6c757d;
  height: 31px;
  line-height: 50px;
  text-align: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button:hover {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  background-color: #b4b4b4;
  color: black;
}

.search-container {
  position: relative;
  display: inline-block;
  height: 31px;
  width: 32px;
  vertical-align: bottom;
}

.searchbutton {
  position: absolute;
  border-radius: 0.25rem;
  /* border-radius: 0 0.25rem  0.25rem 0; */
  font-size: 15px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.search:focus+.searchbutton {
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  border-radius: 0 0.25rem 0.25rem 0;

  background-color: #b4b4b4;
  color: black;
}

.search {
  position: absolute;
  left: 49px;
  background-color: white;
  outline: none;
  border: none;
  padding: 0;
  width: 0;
  height: 100%;
  z-index: 10;
  transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
}

.search:focus {
  width: 215px;
  padding: 0 16px 0 0;
  border: 1px solid #d3d3d3;
  /* border-radius: 0.25rem; */
  border-radius: 0.25rem 0 0 0.25rem;
}

.expandright {
  left: auto;
  right: 32px;
}

.expandright:focus {
  padding: 0 0 0 16px;
}

.clear-icon {
  position: absolute;
  right: 8px;
  /* Ajusta según el espacio necesario */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 12px;
  /* Tamaño pequeño */
  color: #888;
  /* Color gris */
}

.clear-icon:hover {
  color: #000;
  /* Cambia el color al pasar el mouse */
}
</style>
