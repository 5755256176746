import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import keyc from "./module/keycloak";

import "bootstrap";
import "./sass.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ModalPlugin } from "bootstrap-vue";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);
Vue.use(ModalPlugin);

import axios from "axios";
import { showError } from "@justiciacordoba/js-messages";

axios.interceptors.response.use(
  function(response) {
    if (response.data.hayError) {
      console.log(response.data);
      ShowErrorObject(response);
    }
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      window.location.href=app.$store.state.user.logout;
      }
    else
    {
      ShowErrorObject(error.response);
    }
    return Promise.reject(error);
  }
  );
  
  function ShowErrorObject(response) {
    var ErrorList = "";
    if (response?.data.hayError) {
    if (response.data.errores) {
      var ErrorArray = response.data.errores;
      ErrorArray.forEach(function(value) {
        ErrorList += "• " + value.description + "<br>";
      });
    }
    showError(ErrorList);
  }
}

axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'Bearer ' + app.$store.getters.token;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

Vue.config.productionTip = false;

import VuelidateErrorExtractor from "vuelidate-error-extractor";

import { formgroup } from "@justiciacordoba/vue-formgroup";

Vue.use(VuelidateErrorExtractor, {
  template: formgroup,
  messages: {
    required: "El campo {attribute} es requerido",
    minLength: "{attribute} tiene que tener al menos {min} caracteres",
    numeric: "{attribute} tiene que ser numérico",
    maxLength: "{attribute} tiene que tener como máximo {max} caracteres",
    minValue: "El campo {attribute} tiene que ser mayor a {min}",
    email: "El campo debe ser una direccion de correo válida",
    opcionalConCaracteresMinimos:
    "El campo {attribute} puede ser vacio o contener entre {min} y {max} caracteres",
    telefono:
    "El campo {attribute} debe seguir alguno de los siguientes formatos: (000) - 111111222 ; (1111) / 333311222 ; 2123 - 1122334455 ; 888 / 7788994455  ",
  },
});

var app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

if(process.env.NODE_ENV=="production")
{
  keyc(app).then();
}
else
{
  axios.get("./api/Sistema/Usuario").then((response) => {
    if (response.status == 200) {
      console.dir(response);
      app.$store.commit("setEsDependenciaAdmin", response.data.permEsDependenciaAdmin);
      app.$store.commit("setCodigoDependencia", response.data.codigoDependencia);
      app.$store.commit("setNombreDependencia", response.data.nombreDependencia);
      app.$store.commit("setCodigoOrganismo", response.data.codigoOrganismo);
      app.$store.commit("setNombreOrganismo", response.data.nombreOrganismo);
    }
  });

  app.$store.commit("setLoggedIn", true);
  app.$store.commit("setLoggedIn", true);
  //app.$store.commit("setUserInfo", info);

  app.$emit("auth-ready");
}
// app.$on("auth-ready", () => { 
//   app.$mount("#app");
// });